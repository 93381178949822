import React, { useEffect } from "react";
import { Container, Header, Table } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import chart from "../data/rarity.json";
import { Link } from "gatsby";

export default function RarityChart() {
  useEffect(() => {
    const styles =
      "color: yellow;background: black;font-size: 18px;border-left: 1px solid red;border-right: 1px solid red;padding: 10px";
    console.log(
      "%cHi Stranger!                          \n" +
        "Creator: https://twitter.com/diicasses\n" +
        "Dev: https://wa.me/+8801861590250 ",
      styles
    );
  }, []);
  return (
    <>
      <Helmet>
        <title>OOZ1ES - Rarity Chart</title>
        <style>{`body{background-color: #eceef0;}`}</style>
      </Helmet>
      <Container text style={{ paddingBottom: 20 }}>
        <Header
          textAlign="center"
          as="h1"
          style={{
            marginTop: 20,
            fontSize: 38,
            fontFamily: '"Macondo", sans-serif',
          }}
        >
          Rarity Chart
        </Header>
        {Object.keys(chart).map((item, idx) => (
          <Table key={idx} selectable className="chart">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  textAlign="center"
                  className="main-th"
                  colSpan={3}
                >
                  {item}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row style={{ fontSize: 20 }}>
                <Table.HeaderCell
                  style={{
                    backgroundColor: "#555",
                    color: "#fff",
                  }}
                >
                  Variant
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ backgroundColor: "#555", color: "#fff" }}
                  collapsing
                >
                  Total
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    backgroundColor: "#555",
                    color: "#fff",
                    textAlign: "center",
                  }}
                  collapsing
                >
                  Percentage
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {Object.keys(chart[item]).map((key) => (
                <Table.Row key={key}>
                  <Table.Cell>{key}</Table.Cell>
                  <Table.Cell textAlign="center" collapsing>
                    {chart[item][key]}
                  </Table.Cell>
                  <Table.Cell textAlign="center" collapsing>
                    {((chart[item][key] / 9999) * 100).toFixed(2)}%
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ))}

        <Link to="/">
          <div
            style={{
              fontSize: 32,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/ft/o_favicon.png"
              alt="heart"
              style={{ marginRight: 15, height: 40, width: 40 }}
            />{" "}
            Home
          </div>
        </Link>
      </Container>
    </>
  );
}
